<template>
  <div>
    <index-navbar />
    <section class="block relative z-1 bg-blueGray-600">
      <div class="container mx-auto pt-10 pb-20">
        <div class="justify-center flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 mt-20">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 Skolpods
                 <small class="text-xs block mb-5 text-green">Web Design &amp; Development</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2020/01/Skolpods-1.jpg?9a983a&9a983a"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 2 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 PDK International
                 <small class="text-xs block mb-5 text-green">Web Design &amp; Development</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/PDK_Intl.png?9a983a&9a983a"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 3 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 Kappan Online
                 <small class="text-xs block mb-5 text-green">UX/UI Design</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/Kappan.png?9a983a&9a983a"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 4 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 The American Board
                 <small class="text-xs block mb-5 text-green">Web Design &amp; Development</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/ABCTE.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 5 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 Youth For Understanding
                 <small class="text-xs block mb-5 text-green">Branding, Graphic</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/YFU.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 6 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                Will to Win
                 <small class="text-xs block mb-5 text-green">UX/UI Design</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/WTW.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 7 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 The Washington Mystiques
                 <small class="text-xs block mb-5 text-green">UX/UI Design</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/Mystic.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 8 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 Satellite Industry Association
                 <small class="text-xs block mb-5 text-green">Branding, Graphic</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/SIA.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 9 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 T. Howard Foundation
                 <small class="text-xs block mb-5 text-green">Branding, Graphic</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/Thoward.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 10 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                  ICF
                 <small class="text-xs block mb-5 text-green">Branding, Graphic</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/ICF.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 11 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 NIH Gov
                 <small class="text-xs block mb-5 text-green">Web Design & Development</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/NIH.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>
              <!-- 12 -->
              <div class="w-full lg:w-4/12 px-4">
                <h5 class="text-xl font-semibold pb-4 text-center text-white">
                 Satellite Broadcasting & Communications Association
                 <small class="text-xs block mb-5 text-green">UX/UI Design</small>
                </h5>
                
                <!-- <router-link to="/auth/login"> -->
                  <div
                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150"
                  >
                    <img
                      alt="..."
                      class="align-middle border-none max-w-full h-auto rounded-lg"
                      src="https://kprise.com/wp-content/uploads/2019/01/SBCA-1.png"
                    />
                  </div>
                <!-- </router-link> -->
              </div>             

            </div>
          </div>
        </div>
      </div>
    </section>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";

export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>
