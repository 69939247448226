<template>
  <div>
    <navbar />
    <main>
      <section class="pb-20 bg-blueGray-200">
        <div class="container mx-auto px-4 md:pt-20">
          <div class="flex flex-wrap items-start md:pt-20">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h2 class="text-4xl mb-2 font-semibold leading-normal">
                Our services move your business forward.
              </h2>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
               When you work with Orange Bees, you are not just buying a piece of software. 
               You are streamlining your business processes, investing in customer relationships,
                or boosting productivity. At the end of the day, you are improving your bottom line.
              </p>
              <!-- <router-link to="/" class="font-bold text-blueGray-700 mt-8">
                Check Vue Notus!
              </router-link> -->
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500 -mt-10"
              >
                <img
                  alt="..."
                  src="@/assets/img/img1.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Software as an investment
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    We always start by studying in-depth your particular business so the software we create for you will grow your 
                    effectiveness and give you a competitive advantage.
                    Software from Orange Bees is more than just code — 
                    it’s an asset that takes your business to the next level.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="text-emerald-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-emerald-300"
                >
                  <i class="fas fa-rocket text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">A growing company</h3>
                <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  The extension comes with three pre-built pages to help you get
                  started faster. You can change the text and images and you're
                  good to go.
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Carefully crafted components
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Amazing page examples
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-emerald-200 mr-3"
                        >
                          <i class="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Dynamic components</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <section class="pt-20 pb-40">
        <div class="container mx-auto px-4">
          <div class="items-start flex flex-wrap">
            <div class="w-full md:w-4/12 mr-auto px-4 md:pt-24 md:pt-0 mt-10">
              <img
                alt="..."
                class="max-w-full rounded-lg mx-auto"
                style="
                  transform: scale(1) perspective(1040px) rotateY(-11deg)
                    rotateX(2deg) rotate(2deg);
                "
                :src="manLaptop"
              />
            </div>
            <div class="w-full md:w-8/12 ml-auto px-12 md:px-4">
              <div class="md:pr-12">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-file-alt text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">
                  What Can You Do ?
                </h3>
                <!-- <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  This extension comes a lot of fully coded examples that help you
                  get started faster. You can adjust the colors and also the
                  programming language. You can change the text and images and
                  you're good to go.
                </p> -->
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong>Web App Development – </strong> When your business grows, 
                          the software that worked for your less-complex operations no longer does the job. 
                          If your software is struggling to keep up, it’s time for something new that can grow with you. 
                          Using software that can take you to your next stage of growth and 
                          beyond means you can take advantage of every opportunity you encounter.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong>Mobile App Development – </strong> Mobile devices are now an integral part of life. 
                          Your employees and customers use mobile devices for everything from ordering 
                          their morning coffee to depositing a check. Make your software more attractive to use 
                          and increase adoption by offering a mobile app. Employees will find work easier, 
                          and customers will appreciate the simplicity of doing business with you.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Data & Analytics – </strong> The secret to process improvement and business growth lies in your data. 
                          Unlock its power to learn where inefficiencies and bottlenecks lie. 
                          When you’re able to make smarter, more informed decisions, 
                          you’ll blast through the barriers that are keeping
                          your business from reaching your profitability goals.
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> System Integration –  </strong> One of the biggest causes of business inefficiencies is systems 
                          that aren’t fully integrated. If your systems can’t talk to one another, 
                          the need for manual involvement slows you down. Boost efficiency and 
                          get the most out of your software systems and databases by integrating them seamlessly.
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Product Engineering –  </strong> Have a great idea for the next breakthrough product? 
                          From prototyping to design to execution, Orange Bees has the expertise you need to 
                          bring your idea to market. Or, if your project has gone off the rails at another software 
                          development firm, we can rescue it (we’ve done it plenty of times, so we’ve seen it all).
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Enterprise Management –  </strong> You need robust, reliable, and intuitive systems for your complex organization 
                          — including ERP and CRM systems. We architect, develop, and maintain core business systems and can 
                          serve as either as your out-of-house development team or as advisors to your in-house talent. 
                          Say goodbye to inconsistency and inefficiency.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="pb-20 bg-blueGray-200">
        <div class="container mx-auto px-4">         
           <div class="flex flex-wrap items-center">
              <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-drafting-compass text-xl"></i>
                </div>
                <h3 class="text-3xl mb-2 font-semibold leading-normal">
                  How We Work 
                </h3>
                <h6 class="font-semibold">Our Process</h6>
                <p
                  class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
                >
                  We can meet you at any point in the process or start at the beginning.
                  Whether it's a product, integration or system, we take a disciplined and holistic approach.
                </p>               
              </div>
              <div class="w-full md:w-6/12 px-4">
                <div class="flex flex-wrap">
                  <div class="w-full md:w-6/12 px-4">
                    <div class="relative flex flex-col mt-10  shadow bg-white rounded">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-sitemap"></i>
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">
                          INNOVATION
                        </h6>
                        <p class="mb-4 text-blueGray-500">
                          We are always learning, striving to be experts in what we do.
                        </p>
                      </div>
                    </div>
                    <div class="relative flex flex-col min-w-0 mt-10  shadow bg-white rounded">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-drafting-compass"></i>
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">
                          CUSTOMIZATION
                        </h6>
                        <p class="mb-4 text-blueGray-500">
                          We know that every client is unique - we tailor our process to ensure your success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-6/12 px-4">
                    <div class="relative flex flex-col min-w-0 mt-10  shadow bg-white rounded">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-newspaper"></i>
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">COLLABORATION</h6>
                        <p class="mb-4 text-blueGray-500">
                          We work with you as a partner to ensure the success of not only the project, but of our relationship.
                        </p>
                      </div>
                    </div>
                    <div class="relative flex flex-col min-w-0 mt-10  shadow bg-white rounded">
                      <div class="px-4 py-5 flex-auto">
                        <div
                          class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                        >
                          <i class="fas fa-file-alt"></i>
                        </div>
                        <h6 class="text-xl mb-1 font-semibold">
                          DELIVERY
                        </h6>
                        <p class="mb-4 text-blueGray-500">
                          We believe that delivery matters - we deliver what we commit to.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
        </div>
      </section>


      <section class="pb-20 relative block bg-blueGray-800">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        

        <div class="container mx-auto px-4 pb-20 pt-20">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-8/12 px-4">
              <h2 class="text-4xl font-semibold text-white pb-5">
               Our Engagement
              </h2>
              <!-- <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                MyPass LMS combines state-of-the-art technology with in-depth understanding of human learning process.
                It is the go-to training and certification platform that can be easily tailor-made for the unique needs 
                of educational institutes, corporates, e-learning ventures and not-for-profit organizations.
              </p> -->
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                FIXED PRICE
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                For smaller projects with solid requirements and detailed specifications, we take-on all project risks.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                TIME AND MATERIALS
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                For complex projects, we start with requirements gathering and then move through iterations of scoping and delivering.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                DEDICATED TEAM
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                If you simply need bandwidth on a project, our team can supplement your team and take ownership of the results.
              </p>
            </div>
          </div>
         
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                ON-SITE
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                We come to you and sit side-by-side with your team.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                REMOTE
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                We take the project back to our shop and bring you the deliverable when complete.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
               HYBRID
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                We spend quality in-person time with your team and then iterate via email and video calls.              
              </p>
            </div>
          </div>
        </div>
      </section>

      
      <!-- <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">
                    Want to work with us?
                  </h4>
                  <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Full Name"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Type a message..."
                    />
                  </div>
                  <div class="text-center mt-6">
                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import manLaptop from "@/assets/img/man-laptop.png";
import documentation from "@/assets/img/documentation.png";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      manLaptop,
      documentation
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
