<template>
  <div>
    <index-navbar />
    <section
      class="header relative pt-16 items-start flex md:h-screen md:max-h-860-px"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="md:pt-20 pt-5">
            <h2 class="font-semibold text-4xl text-blueGray-600">
              Powerful, Customizable & Affordable Technology Solutions for Growth-Oriented Enterprises
            </h2>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              East West Technologies solves the most critical technology needs of organizations of all sizes and types. Be it enhancing your digital presence, mapping your buyer’s journey, or training your team - we have your back. Shift your IT burden to us, and see the magic happen!
            </p>
            <div class="mt-12">
              <router-link
                 to="/portfolio"            
                class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Our Portfolio
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <img
        class="hidden md:block absolute b-auto right-0 pt-5 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px"
        src="../assets/img/headerBG.png"
        alt="..."
      />
    </section>

    <section class="mt-48 md:mt-40 pb-20 relative bg-blueGray-100">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div class="container mx-auto">
        <div class="flex flex-wrap items-center">
          <div
            class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 px-0 mr-auto ml-auto -mt-20"
          >
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
            >
              <img
                alt="..."
                src="@/assets/img/img2.jpg"
                class="w-full align-middle rounded-t-lg"
              />
              <blockquote class="relative p-8 mb-4">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 583 95"
                  class="absolute left-0 w-full block h-95-px -top-94-px"
                >
                  <polygon
                    points="-30,95 583,95 583,65"
                    class="text-emerald-500 fill-current"
                  ></polygon>
                </svg>
                <h4 class="text-xl font-bold text-white">
                  Top Notch Services
                </h4>
                <p class="text-md font-light mt-2 text-white">
                  We empower organizations – whether businesses or non-profits – 
                  to go beyond traditional & siloed tools such as emails, excel sheets, folders, 
                  drives, and paper-based information. 
                </p>
              </blockquote>
            </div>
          </div>

          <div class="w-full md:w-6/12 px-4">
              <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-user-friends text-xl"></i>
                </div>
                <h3 class="text-xl mb-2 font-semibold leading-normal">
                  Automate & Outsource Your Enterprise Processes & Workflows -
                  We Make Enterprise Technology Simple, Affordable & Efficient
                </h3>
                <p
                  class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
                >
                  We develop, customize and integrate products 
                  and services that meet your core technology needs as well as your unique 
                  customization requirements.
                </p>
                <p
                  class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
                >
                  Our flagship products like MyPass LMS and MyPass CRM coupled with our dedicated services like Website Development, 
                  SEO and Customer Service ensure that you get the best of industry-standard technology & 
                  talent without ever having to worry about managing them.
                </p>
            </div>
        </div>
      </div>

      <div class="container mx-auto overflow-hidden pb-20">
        <div class="flex flex-wrap items-end">
          <div class="w-full md:w-6/12  md:px-4 ml-auto mr-auto mt-20">
            <div class="md:pr-12">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-file-alt text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">
                  MyPass LMS - A Fully Customizable Cloud-LMS for Training & Certification
                </h3>
                <!-- <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  This extension comes a lot of fully coded examples that help you
                  get started faster. You can adjust the colors and also the
                  programming language. You can change the text and images and
                  you're good to go.
                </p> -->
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Ready-to-deploy in minutes
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Create new course content quickly
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Built-in Course Manager
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Features like Gamification & Forums
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Learners earn XP, badges & climb leader-boards
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          White-label your LMS design & layout
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
          </div>
          <div class="hidden md:block  w-full md:w-4/12 mr-auto px-4 pt-24 md:pt-0">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-xl"
              style="
                transform: scale(1) perspective(1040px) rotateY(-11deg)
                  rotateX(2deg) rotate(2deg);
              "
              src="../assets/img/b1.png"
            />
          </div>
          <!-- <div class="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32">
            <img
                alt="..."
                src="../assets/img/b1.png"
                class="w-full align-middle rounded"
              />
          </div> -->
        </div>
      </div>

      <div class="container mx-auto px-4 pb-32 ">
        <div class="items-center flex flex-wrap">          
          <div class="hidden md:block  w-full md:w-5/12 mr-auto px-4 md:pt-0 pb-20">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-xl"
              style="
                transform: scale(1) perspective(1040px) rotateY(20deg)
                  rotateX(2deg) rotate(2deg);
              "
              src="../assets/img/b2.png"
            />
          </div>
          <div class="w-full md:w-6/12 ml-auto md:px-4">
            <div class="md:pr-12">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-file-alt text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">
                MyPass CRM - Map Your Buyer’s Journey & Grow Like a Pro
              </h3>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Close deals faster
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Nurture converted leads
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Revive dead leads
                      </h4>
                    </div>
                  </div>
                </li>

                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Integrate with MyPass LMS / other 3rd party applications
                      </h4>
                    </div>
                  </div>
                </li>

                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Perform deep customization
                      </h4>
                    </div>
                  </div>
                </li>

                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Boost your team’s performance!
                      </h4>
                    </div>
                  </div>
                </li>                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-16 bg-blueGray-200 relative pt-32">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto">
        <div
          class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16  relative z-10"
        >
          <div class="w-full text-center lg:w-8/12">
            <!-- <p class="text-4xl text-center"> -->
              <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-file-alt text-xl"></i>
              </div>
            <!-- </p> -->
            <h3 class="font-semibold text-3xl">
              Our Services
            </h3>
            <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-6">
              Eeast West Technologies also provides Web Development, Search Engine Optimization, 
              and Customer Support Services to drive brand awareness and 
              keep things running smoothly.
            </p>
          </div>
          <div class="container mx-auto px-4">
            <div class="mt-5 text-center flex-col flex md:flex-row justify-center">
              <router-link
                to="/software-developments"
                class="get-started text-white font-bold px-6 py-4 mb-2 mr-0 md:mr-2 rounded outline-none focus:outline-none bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 ml-2 mr-0 md:mr-2"
              >
                Software Development
              </router-link>
              <router-link
                to="/data-analytics-and-insights"
                class="github-star text-white font-bold px-6 py-4 rounded outline-none focus:outline-none bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150 mb-2 mx-5 md:ml-2 ml-2"                
              >
                Data analytics And Insights
              </router-link>
            </div>            
          </div>
        </div>
      </div>
    </section>
    <footer-component />
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";

export default {
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
    };
  },
  components: {
    IndexNavbar,
    FooterComponent,
  },
};
</script>
