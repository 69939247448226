<template>
  <div>
    <navbar />
    <main>
      <div
        class="relative pt-20 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="'background-image: url('+img3+')'"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <h1 class="text-white font-semibold text-5xl">
                Learning is Indispensable for Growth - Make it Happen with MyPass LMS
              </h1>
              <p class="mt-4 text-lg text-blueGray-200">
                A Fully Customizable Cloud-LMS to Meet Your Unique Training & Certification Needs
              </p>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>

      <section class="pb-20 bg-blueGray-200 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <i class="fas fa-award"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Awarded Agency</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Divide details about your product or agency work into parts.
                    A paragraph describing a feature will be enough.
                  </p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400"
                  >
                    <i class="fas fa-retweet"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Free Revisions</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Keep you user engaged by providing meaningful information.
                    Remember that by this time, the user is curious.
                  </p>
                </div>
              </div>
            </div>

            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400"
                  >
                    <i class="fas fa-fingerprint"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Verified Company</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Write a few lines about each one. A paragraph describing a
                    feature will be enough. Keep you user engaged!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                Customizable, Scalable & Affordable LMS for Organizations of 
                All Sizes & Budgets - Extend Learning Beyond Your Team - 
                Train Customers & Partners for Better On-boarding!
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
                Our other customers include corporate, not-for-profits and 
                educational institutes. 
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
              >
                We empowers you to align your training & certification activities 
                with your strategic goals without making a dent in your pocket.
              </p>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
              >
                <img
                  alt="..."
                  src="@/assets/img/img1.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    MyPass LMS is a leading training and certification platform catering to enterprises from the
                    e-learning industry who train individual professionals & students as well as B2B training platforms 
                    catering to corporate training solutions.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-20 pb-40">
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                class="max-w-full rounded-lg mx-auto"
                style="
                  transform: scale(1) perspective(1040px) rotateY(-11deg)
                    rotateX(2deg) rotate(2deg);
                "
                :src="manLaptop"
              />
            </div>
            <div class="w-full md:w-8/12 ml-auto px-12 md:px-4">
              <div class="md:pr-12">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-file-alt text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">
                  What Can You Do with MyPass LMS?
                </h3>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong>Induction & Onboarding – </strong> Introduce your new recruits to your organization history, 
                          mission & vision, culture, code of conduct and organization structure
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong>Marketing & Branding – </strong> Up-skill your marketing team to align 
                          them with the latest trends in digital marketing, 
                          media management and brand building
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Leads Generation & Sales – </strong> Get your sales team to have their A-game on by 
                          equipping them with knowledge on cold-calling, leads generation & nurturing, 
                          and closing sales
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Legal & Compliance –  </strong> Keep your team updated on the latest regulations, 
                          statutory requirements, existing processes, industry-wide best practices & much more
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Soft-Skills & Grooming  –  </strong> Impart soft-skills training like communication skills, 
                          team building, interpersonal skills, leadership skills, presentation skills 
                          & much more
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li class="py-2">
                    <div class="flex items-start">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                        >
                          <i class="fas fa-hand-point-right"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          <strong> Professional Certifications  –  </strong> Specific training solutions tailored to your need 
                          for professionally certified employees – e.g. PMI, SAP, Six Sigma, IRFS, etc.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="pb-20 bg-blueGray-200 pt-24 pb-48">
        <div class="container mx-auto px-4">         
          <div class="flex flex-wrap items-center">            
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
              >
                <img
                  alt="..."
                  src="@/assets/img/img1.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                   Why Choose MyPass LMS?
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    MyPass LMS is much more than a learning tool.
                    We have given it super-powers to make it the central hub of your enterprise innovation,
                    communication, and growth.
                  </p>
                </blockquote>
              </div>
            </div>


            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                Engaging Learning Experience Powered by Cutting-Edge Technologies
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Features like Gamification, Discussion Forums, Blogs, Assessments, Exams, and more
                      </h4>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        We make it fun! Learners are motivated by earning XP, badges & climbing leaderboards
                      </h4>
                    </div>
                  </div>
                </li>


                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        MyPass LMS is state-of-the art & is compliant with SCORM and Tin Can API (xAPI)
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-20">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                Full-Spectrum Cloud-LMS Coupled with Powerful Third-Party Integrations
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Integrate with MyPass CRM and other 3rd party applications to meet your unique needs
                      </h4>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Extend your LMS – connect with communication tools, social media & more
                      </h4>
                    </div>
                  </div>
                </li>


                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Location-independent cloud platform – train & certify anyone, anywhere, anytime
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
             </div>

            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto mt-20">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas  fa-user-friends  text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                Fully Personalized Training and Certification Platform Like No Other
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Customize LMS features as per your needs anytime, anywhere with zero hassle
                      </h4>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        White-label your LMS by adding your logo, color scheme, and custom layout and domain
                      </h4>
                    </div>
                  </div>
                </li>


                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Extend learning beyond your team – train customers & partners for better on-boarding!
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <section class="pb-20 relative block bg-blueGray-800">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        

        <div class="container mx-auto px-4 pt-20 pb-20">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-8/12 px-4">
              <h2 class="text-4xl font-semibold text-white">
                Key Features
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                MyPass LMS combines state-of-the-art technology with in-depth understanding of human learning process.
                It is the go-to training and certification platform that can be easily tailor-made for the unique needs 
                of educational institutes, corporates, e-learning ventures and not-for-profit organizations.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Smooth Setup and Implementation
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our team will walk with you from the contract signing through 
                the first successful course completion and beyond.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Scalable
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                MyPass LMS can handle the complex certification 
                needs of a large corporation or the basic needs of a budding non-profit.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Customizable
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                We'll work with you to create an effective learning 
                environment by leveraging the right features for your needs.
              </p>
            </div>
          </div>
         
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Built-in CRM
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                MyPass LMS integrates with MyPass CRM to keep 
                track of course outcomes and other valuable information.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                In-app Support
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our live-chat and phone support teams are available 24/7
                to ensure a hassle-free learning experience for your staff.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Built For Non-Profits
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Built to manage the internal / external / employee trainings of non-profit 
                organizations with efficiency and ease.                
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import manLaptop from "@/assets/img/man-laptop.png";
import img3 from "@/assets/img/img3.jpg";
import documentation from "@/assets/img/documentation.png";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      manLaptop,
      documentation,
      img3
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
