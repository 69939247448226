<template>
  <nav
    class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg bg-white shadow"
  >
    <div
      class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link to="/"
            class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          >
             <img
                      alt="..."
                      src="../../assets/img/logo.png"
                      class="w-48"
                      style="width:15em"
                    />          
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center">
            <router-link
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              to="/"
            >
              Home
            </router-link>
          </li>

          <li class="flex items-center">
            <router-link
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              to="/product/lms"
            >
              <!-- <i
                class="text-blueGray-400 fa-solid fa-chalkboard-user text-lg leading-lg mr-2"
              /> -->
              
              Product LMS
            </router-link>
          </li>

          <li class="flex items-center">
            <router-link
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              to="/portfolio"
            >
              <!-- <i
                class="text-blueGray-400 fa-solid fa-chalkboard-user text-lg leading-lg mr-2"
              /> -->
              
              Portfolio
            </router-link>
          </li>


          <li class="flex items-center">
            <router-link
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              to="/software-developments"
            >
              <!-- <i
                class="text-blueGray-400 fa-solid fa-chalkboard-user text-lg leading-lg mr-2"
              /> -->
              
              Software Development
            </router-link>
          </li>

          

          

          <li class="flex items-center">
            <router-link
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              to="/data-analytics-and-insights"
            >
              <!-- <i
                class="text-blueGray-400 fas fa-analytics text-lg leading-lg mr-2"
              /> -->
              
              Data analytics And Insights
            </router-link>
          </li>
        </ul>
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="items-center">
            <!-- <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 flex items-center text-xs uppercase font-bold"
              href="javascript:void(0)"
            >
              <i class="text-blueGray-400 fas fa-address-book text-lg leading-lg" />
              <span class="inline-block ml-2">+1 20213213</span>
            </a> -->
             <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 flex items-center text-xs uppercase font-bold"
              href="javascript:void(0)"              
            >
              <i class="text-blueGray-400 fas fa-envelope  text-lg leading-lg"></i>
              <span class="inline-block ml-2">shaider@eastwestechnologies.com</span>
            </a>            
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
};
</script>
