<template>
  <div>
    <navbar />
    <main>
      <div
        class="relative pt-20 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="'background-image: url('+img3+')'"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <h1 class="text-white font-semibold text-5xl">
               Data analytics And Insights
              </h1>
            </div>
          </div>
        </div>        
      </div>

      <section class="pb-20 bg-blueGray-200 ">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto pt-20 md:pt-0">
              <div
                class="text-blueGray-500 p-3 pt-5 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                Customizable, Scalable & Affordable LMS for Organizations of 
                All Sizes & Budgets - Extend Learning Beyond Your Team - 
                Train Customers & Partners for Better On-boarding!
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
              >
                Our other customers include corporate, not-for-profits and 
                educational institutes. 
              </p>
              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600"
              >
                We empowers you to align your training & certification activities 
                with your strategic goals without making a dent in your pocket.
              </p>
            </div>

            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto mt-5">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
              >
                <img
                  alt="..."
                  src="@/assets/img/img1.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-emerald-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    Top Notch Services
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    MyPass LMS is a leading training and certification platform catering to enterprises from the
                    e-learning industry who train individual professionals & students as well as B2B training platforms 
                    catering to corporate training solutions.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-20">
        <div class="container mx-auto px-4">
          <img
                alt="..."
                class="max-w-full rounded-lg mx-auto img-fluid w-3/5"
                src="@/assets/img/data-analytics.png"
              />
        </div>
      </section>


      <section class="pb-20 bg-blueGray-200 pt-20 pb-48">
        <div class="container mx-auto px-4">  
          <div class="w-full px-4 mr-auto ml-auto">
              <h4 class="font-semibold text-3xl text-center my-3">THE FIVE STAGES OF DATA ANALYSIS</h4>
              <img
              alt="..."
              class="max-w-full w-3/5 rounded-lg mx-auto img-fluid mt-10"
              src="@/assets/img/the-data-analysis-process-1.jpg"
            />
          </div>

          <!-- Step 1 -->
          <div class="flex flex-wrap items-center mt-20">                 
            <div class="w-2/12 px-4 ml-auto hidden md:block">
               <img
                  alt="..."
                  src="@/assets/img/stage1.png"
                  class="align-middle rounded-t-lg"
                />
            </div>


            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                1. Step one: Defining the question
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        The first step in any data analysis process is to define your objective. In data analytics jargon, this is sometimes called the ‘problem statement’.
                      </h4>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Defining your objective means coming up with a hypothesis and figuring how to test it. Start by asking: What business problem am I trying to solve? While this might sound straightforward, it can be trickier than it seems. For instance, your organization’s senior management might pose an issue, such as: “Why are we losing customers?” It’s possible, though, that this doesn’t get to the core of the problem. A data analyst’s job is to understand the business and its goals in enough depth that they can frame the problem the right way.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Step 2 -->
          
          <div class="flex flex-wrap items-center mt-20">    
            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                2. Step two: Collecting the data
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Once you’ve established your objective, you’ll need to create a strategy for collecting and aggregating the appropriate data. A key part of this is determining which data you need. This might be quantitative (numeric) data, e.g. sales figures, or qualitative (descriptive) data, such as customer reviews. All data fit into one of three categories: first-party, second-party, and third-party data. Let’s explore each one.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="w-2/12 px-4 mr-auto  hidden md:block">
               <img
                  alt="..."
                  src="@/assets/img/stage2.jpg"
                  class="align-middle rounded-t-lg"
                />
            </div>
          </div>

          <!-- Step 3 -->
          
          <div class="flex flex-wrap items-center mt-20">                 
            <div class="w-2/12 px-4 ml-auto  hidden md:block">
               <img
                  alt="..."
                  src="@/assets/img/stage3.png"
                  class="align-middle rounded-t-lg"
                />
            </div>


            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                3. Step three: Cleaning the data
              </h3>
              <p>Once you’ve collected your data, the next step is to get it ready for analysis. This means cleaning, or ‘scrubbing’ it, and is crucial in making sure that you’re working with high-quality data. Key data cleaning tasks include:</p>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        <strong>Removing major errors, duplicates, and outliers — </strong> all of which are inevitable problems when aggregating data from numerous sources.
                      </h4>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        <strong>Removing unwanted data points — </strong> extracting irrelevant observations that have no bearing on your intended analysis.
                      </h4>
                    </div>
                  </div>
                </li>


                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        <strong>Bringing structure to your data — </strong> general ‘housekeeping’, i.e. fixing typos or layout issues, which will help you map and manipulate your data more easily.
                      </h4>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        <strong> Filling in major gaps — </strong> as you’re tidying up, you might notice that important data are missing. Once you’ve identified gaps, you can go about filling them.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Step 4 -->

          
          <div class="flex flex-wrap items-center mt-20">   
            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                4. Analyze the data
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Defining your objective means coming up with a hypothesis and figuring how to test it. Start by asking: What business problem am I trying to solve? While this might sound straightforward, it can be trickier than it seems. For instance, your organization’s senior management might pose an issue, such as: “Why are we losing customers?” It’s possible, though, that this doesn’t get to the core of the problem. A data analyst’s job is to understand the business and its goals in enough depth that they can frame the problem the right way.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="w-2/12 px-4 mr-auto  hidden md:block">
               <img
                  alt="..."
                  src="@/assets/img/stage4.png"
                  class="align-middle rounded-t-lg"
                />
            </div>
          </div>

          <!-- Step 5 -->
          
          <div class="flex flex-wrap items-center mt-20">                 
            <div class="w-2/12 px-4 ml-auto  hidden md:block">
               <img
                  alt="..."
                  src="@/assets/img/stage5.jpg"
                  class="align-middle rounded-t-lg"
                />
            </div>


            <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
              <div
                class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-2 shadow-lg rounded-full bg-white"
              >
                <i class="fas fa-user-friends text-xl"></i>
              </div>
              <h3 class="text-xl mb-2 font-semibold leading-normal">
                5. Visualize and share your finding
              </h3>
              <ul class="list-none">
                <li class="py-2">
                  <div class="flex items-start">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-500 bg-blueGray-50 mr-3"
                      >
                        <i class="fas  fa-hand-point-right"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-blueGray-500">
                        Once you’ve established your objective, you’ll need to create a strategy for collecting and aggregating the appropriate data. A key part of this is determining which data you need. This might be quantitative (numeric) data, e.g. sales figures, or qualitative (descriptive) data, such as customer reviews. All data fit into one of three categories: first-party, second-party, and third-party data. Let’s explore each one.
                      </h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </section>


      <section class="pb-20 relative block bg-blueGray-800">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        

        <div class="container mx-auto px-4 lg:pt-24 lg:py-20">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-8/12 px-4">
              <h2 class="text-4xl font-semibold text-white">
                Key Features
              </h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                MyPass LMS combines state-of-the-art technology with in-depth understanding of human learning process.
                It is the go-to training and certification platform that can be easily tailor-made for the unique needs 
                of educational institutes, corporates, e-learning ventures and not-for-profit organizations.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Smooth Setup and Implementation
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our team will walk with you from the contract signing through 
                the first successful course completion and beyond.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Scalable
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                MyPass LMS can handle the complex certification 
                needs of a large corporation or the basic needs of a budding non-profit.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Customizable
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                We'll work with you to create an effective learning 
                environment by leveraging the right features for your needs.
              </p>
            </div>
          </div>
         
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-medal text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Built-in CRM
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                MyPass LMS integrates with MyPass CRM to keep 
                track of course outcomes and other valuable information.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-poll text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                In-app Support
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Our live-chat and phone support teams are available 24/7
                to ensure a hassle-free learning experience for your staff.
              </p>
            </div>
            <div class="w-full lg:w-4/12 px-4 text-center">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-lightbulb text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Built For Non-Profits
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Built to manage the internal / external / employee trainings of non-profit 
                organizations with efficiency and ease.                
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";
import manLaptop from "@/assets/img/man-laptop.png";
import documentation from "@/assets/img/documentation.png";
import img3 from "@/assets/img/img3.jpg";

export default {
  data() {
    return {
      team1,
      team2,
      team3,
      team4,
      manLaptop,
      documentation,
      img3
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
};
</script>
